import { useLDFlag, useLDClient } from 'launchdarkly-vue-client-sdk';

export function useLaunchDarkly(flag: string) {
  let flagValue = ref(false);

  if (process.client) {
    flagValue = useLDFlag(flag, false);
    const ldClient = useLDClient();

    // --- Contentsquare Integration Start --- https://support.contentsquare.com/hc/en-gb/articles/4408776846994-LaunchDarkly
    function callCShandler() {
      window.CS_CONF.integrations_handler.launchdarkly(ldClient);
    }

    if (
      window.CS_CONF &&
      window.CS_CONF.integrations_handler &&
      window.CS_CONF.integrations_handler.launchdarkly
    ) {
      callCShandler();
    } else {
      window.addEventListener('Contentsquare_LD_Integration_Launch', () => {
        callCShandler();
      });
    }
    // --- Contentsquare Integration End ---
  }

  return flagValue;
}
